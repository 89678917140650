import React from 'react'

const CommonBanner = ({data}) => {
  return (
    <div className="page-header section-padding style-dark full-height dc-three left-col-full" style={{ backgroundImage: `url(${data.bgImage})` }}>
        <div className="container">
            <div className={`row align-items-center ${data.rowReverse}`}>
                <div className="col-lg-5">
                    <div className="image-wrapper" data-aos="fade-up" data-wow-delay="0.2s">
                        <img src={data.image} alt="" className='img-fluid' />
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="heading-wrapper" data-aos="fade-right"  data-wow-delay="0.2s">
                        <div dangerouslySetInnerHTML={{ __html: data.heading }} />
                    </div>
                    <div className="text-wrapper" data-aos="fade-right" data-wow-delay="0.4s">
                        <h6>{data.description}</h6>
                    </div>
                    <div data-aos="fade-right"><button type="button" className='common-btn padding-common-btn'>Get Started For Free</button></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CommonBanner
