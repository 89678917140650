import React from 'react'
import img1 from '../images/detailImage/hero-hr.png'
import bg1 from '../images/detailImage/bg1.png'
import { RiDatabaseLine } from "react-icons/ri";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { HiOutlineCalculator } from "react-icons/hi2";
import { RiTimerLine } from "react-icons/ri";
import { GiStairsGoal } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";
import { BsFilePostFill } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { VscGraphLine } from "react-icons/vsc";
import { RiSkull2Line } from "react-icons/ri";
import ServiceCommon from './ServiceCommon';
import FeatureCommon from './FeatureCommon'
import CommonBanner from './CommonBanner';

const HrisComponent = () => {
    const data = {
        "heading": '<h2>How Shopersbay HRIS Enhances <span class="primary-text">Business Growth</span></h2>',
        "description": "HRIS streamlines HR operations, automates processes, and enhances productivity, driving business growth through efficient workforce management",
        "colsize": "col-lg-4 col-12",
        "list": [
            {
                "title": "Remote Enablement",
                "icons": <MdManageAccounts size={70} />,
                "discrp": "Remote HR management enables productivity, payroll, and engagement"
            },
            {
                "title": "Retention Engagement",
                "icons": <VscGraphLine size={70} />,
                "discrp": "An HRIS boosts retention with performance tracking and career development"
            },
            {
                "title": "Compliance Automation",
                "icons": <RiSkull2Line size={70} />,
                "discrp": "Effective asset management ensures legal compliance, reducing the risks of legal issues"
            }
        ]
    }
    const data2 = {
        "heading": '<h2>The Benefits of Implementing <span class="primary-text">ShopersBay HRIS</span></h2>',
        "bgImage": bg1,
        "description": "Companies across various industries rely on ShopersBay’s HRIS to simplify complex HR operations. Unlike traditional HR management methods that involve paperwork and spreadsheets, ShopersBay’s cloud-based HRIS ensures data accuracy, security, and real-time access.",
        "listdata": [
            {
                "title": "Cost Savings",
                "discrp": "ShopersBay’s HRIS reduces administrative costs by automating tasks that traditionally require extensive manpower. Companies can eliminate paper-based HR processes, reducing expenses on documentation, storage, and manual payroll calculations",
            },
            {
                "title": "Increased Productivity",
                "discrp": "Automation reduces the time HR teams spend on repetitive tasks. With streamlined processes, HR professionals can focus on strategic planning, employee engagement, and talent development, ultimately improving overall productivity",
            },
            {
                "title": "Improved Employee Experience",
                "discrp": "A modern HRIS enhances the employee experience by providing self-service options, mobile access, and automated workflows. Employees can request leave, update personal information, and access HR services effortlessly",
            },
            {
                "title": "Data Security & Compliance",
                "discrp": "ShopersBay ensures that employee data is stored securely and complies with GDPR, labor laws, and industry-specific regulations. The system also provides detailed audit trails to monitor data access and modifications",
            },
            {
                "title": "Real-Time Analytics & Insights",
                "discrp": "HR managers can generate customized reports and analytics on workforce trends, employee performance, and payroll costs. These insights help businesses make data-driven HR decisions",
            },
        ]
    }
    const data3 = {
		"heading": '<h1>Efficient HR Solutions for  <span class="primary-text">Modern Workforces </span></h1>',
		"description": "In today’s competitive business environment, organizations need efficient human resource management systems. Human Resource Information Systems (HRIS) streamline HR processes, optimize workforce management, and enhance productivity. ShopersBay, a leading HRIS provider, offers comprehensive tools to automate and simplify HR operations",
		"image": img1,
		"bgImage": "",
		"rowReverse": "flex-row-reverse"
	}
    return (
        <div className='hris-component'>
            <div className="position-relative">
                {/* <div className="page-header section-padding style-dark full-height dc-thirteen">
                    <div className="container">
                        <div className="row align-items-center flex-row-reverse">
                            <div className="col-lg-6">
                                <div className="image-wrapper" data-aos="fade-up" data-wow-delay="0.2s">
                                    <img src={img1} alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="heading-wrapper with-separator" data-aos="fade-left" data-wow-delay="0.2s">
                                    <h1>Efficient HR Solutions for  <span className='primary-text'>Modern Workforces </span></h1>
                                </div>
                                <div className="text-wrapper" data-aos="fade-left" data-wow-delay="0.2s">
                                    <h6>
                                        In today’s competitive business environment, organizations need efficient human resource management systems. Human Resource Information Systems (HRIS) streamline HR processes, optimize workforce management, and enhance productivity. ShopersBay, a leading HRIS provider, offers comprehensive tools to automate and simplify HR operations.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <CommonBanner data={data3} />
            </div>
            <div className="section-2 section-padding">
                <div className="container">
                    <div className="row clearfix justify-content-center">
                        <div className="col-lg-10">
                            <div className="heading-wrapper text-center with-separator">
                                <h2>Features of ShopersBay’s <span className='primary-text'>HRIS Solutions</span></h2>

                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="empty-space-30"></div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <RiDatabaseLine size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Employee Database Management</h4>
                                        <p>
                                            A centralized system for storing and organizing employee records, enabling quick access and updates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0.2s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <BsFilePostFill size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Recruitment & Applicant Tracking</h4>
                                        <p>
                                            An applicant tracking system (ATS) to streamline hiring, post job openings, and manage candidates efficiently.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0.4s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <HiOutlineClipboardDocumentList size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Onboarding & Training Management</h4>
                                        <p>
                                            Digital onboarding tools, electronic document signing, and training modules for smooth new-hire integration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0.4s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <HiOutlineCalculator size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Payroll & Benefits Administration</h4>
                                        <p>
                                            Automated payroll processing, tax calculations, and benefits management through a self-service portal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0.4s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <RiTimerLine size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Time & Attendance Tracking</h4>
                                        <p>
                                            Integration with biometric systems, time clocks, and remote tracking tools for accurate work-hour monitoring.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0.4s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <GiStairsGoal size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Performance Management</h4>
                                        <p>
                                            Goal setting, performance appraisals, and real-time feedback for employee development and productivity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-block theme-one" data-aos="fade-up" data-wow-delay="0.4s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <TbReportAnalytics size={90} />
                                    </div>
                                    <div className="text">
                                        <h4>Compliance & Reporting</h4>
                                        <p>
                                            Automated compliance tracking, audit-ready reports, and alerts to meet labor law requirements.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeatureCommon data={data2} />
            <ServiceCommon data={data} />
        </div>
    )
}

export default HrisComponent
