import React from 'react'

const CommonFeatureComponent = ({data}) => {

  return (
    <div className="features-section section-padding">
        <div className="container">
          <div className={`row clearfix align-items-center justify-content-between  ${data.rowReverse}`}>
            <div className="col-lg-6">
              <div className="row inner-row clearfix">
                {data.list.map((ele, i)=>{
                    return(
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="features-block theme-one" data-aos="fade-up">
                            <div className="inner-box">
                            <div className="icon">
                               {ele.icon}
                            </div>
                            <div className="text">
                                <h5>{ele.title}</h5>
                                <p>{ele.descpt}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
                
                
              </div>
            </div>

            <div class="col-lg-5">
              <div class="heading-wrapper with-separator">
                <div dangerouslySetInnerHTML={{ __html: data.heading }} />
              </div>

              <div class="text-wrapper">
                <h6>{data.description}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default CommonFeatureComponent
