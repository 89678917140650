import React from 'react'
import * as common from '../components'

const CrmDetail = () => {
    return (
        <>
            <common.Header />
            <common.CrmComponent />
            <common.Pricing />
            <common.Faqs />
            <common.Ready />
            <common.Footer />
        </>
    )
}

export default CrmDetail
