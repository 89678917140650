import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchModuleData, addSelectedModule } from '../redux/slice/onLoad';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPlus, FaMinus } from "react-icons/fa6";
import ing1 from '../images/pricing-ellipse.webp';
import ing2 from '../images/pricing-mob.webp';
import img1 from '../images/module-ellipse.webp'
import { useMediaQuery } from 'react-responsive';

const Pricing = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const pathSegments = window.location.pathname.split("/");
  const module = pathSegments[pathSegments.length - 1]; 

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const { moduledata, selectedModules } = useSelector((state) => state.master);
  const [currency, setCurrency] = useState("USD");
  const [activeButton, setActiveButton] = useState("month");
  const currencysymbol = moduledata && moduledata[0].child_list[0].currency;
  console.log("module", module)
  // Fetch module data on component load
  // useEffect(() => {
  //   dispatch(fetchModuleData({ duration: "month", duration_type: "free_trial" }));
  // }, []);
  const selectedIds = selectedModules.map(module => module.id);
  localStorage.setItem("selectedIds", selectedIds)
  const datatransferclick = () => {
    const data = localStorage.getItem('selectedIds');
    // window.open(`https://login.shopersbay.com/module?q=shopersbay&data=${encodeURIComponent(data)}`, '_blank');
    // window.open(`http://localhost:3001/module?q=shopersbay?q=shopersbay&data=${encodeURIComponent(data)}`, '_blank');
    // window.location.href = `http://localhost:3001/module?q=shopersbay&data=${encodeURIComponent(data)}`;

    const url = `http://login.shopersbay.com/module?q=shopersbay&data=${encodeURIComponent(data)}&planType=${encodeURIComponent(activeButton)}`;
    window.open(url, '_blank');
  }

  // Sort module data based on position
  const sortedMenuList = (moduledata || [])
    .map(item => ({ ...item, child_list: [...item.child_list] }))
    .sort((a, b) => a.child_list[0].position - b.child_list[0].position);

  // Toggle module selection
  const toggleModuleSelection = (id) => {
    const selectedModule = moduledata
      .flatMap(item => item.child_list)
      .find(module => module.id === id);

    if (selectedModule) {
      const isAlreadySelected = selectedModules.some(module => module.id === id);
      const updatedModules = isAlreadySelected
        ? selectedModules.filter(module => module.id !== id)
        : [...selectedModules, selectedModule];
      dispatch(addSelectedModule(updatedModules));
    }
  };

  // Calculate total price with tax
  const calculateTotalPrice = () => {
    return selectedModules.reduce((total, module) => {
      // const priceWithTax = parseFloat(module.price) + (parseFloat(module.price) * parseFloat(module.tax_percent) / 100);
      const priceWithTax = parseFloat(module.price);
      return total + priceWithTax;
    }, 0);
  };

  // Calculate discount for annual plan
  const calculateDiscount = () => {
    if (activeButton === "annual") {
      return selectedModules.reduce((totalDiscount, module) => {
        const annualDiscount = parseFloat(module.annual_discount) / 100; // Convert percentage to decimal
        // const priceWithTax = parseFloat(module.price) + (parseFloat(module.price) * parseFloat(module.tax_percent) / 100); // Price including tax
        const priceWithTax = parseFloat(module.price); // Price including tax
        return totalDiscount + (priceWithTax * annualDiscount * 12); // Add this module's annual discount
      }, 0);
    }
    return 0;
  };
  // formating currency
  const formatCurrency = (value, currency = "") => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency, // Change to any currency like "EUR", "INR", "GBP"
      minimumFractionDigits: 0,
    }).format(value)
    .replace(/\u00A0/, " ");
  };
  

  const totalPrice = calculateTotalPrice();
  const discount = calculateDiscount();
  const finalPrice = activeButton === "annual" ? totalPrice * 12 - discount : totalPrice;




  return (
    <div className="pricing-section position-relative" id="pricing">
      <img
        src={isMobile ? ing2 : img1}
        alt="Background" style={{ transform: "scaleY(-1)" }}
        className={`img-fluid position-absolute ${isMobile ? "top-0" : "top-0"}`}
      />
      <Container className="position-relative z-2">
        {/* Header */}
        <Row>
          <Col lg={8}>
            <h5 className="primary-text">Flexible Pricing Plans</h5>
            <h2>Scalable Solutions Designed to Empower Businesses Across Industries</h2>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-end">
            <div className="text-end">
              <button
                className={`monthly-btn ${activeButton === "month" ? "active" : ""}`}
                onClick={() => setActiveButton("month")}
              >
                Monthly
              </button>
              <button
                className={`yearly-btn ${activeButton === "annual" ? "active" : ""}`}
                onClick={() => setActiveButton("annual")}
              >
                Yearly
              </button>
            </div>
          </Col>
        </Row>

        {/* Modules */}
        <Row className={`${isMobile ? "mt-4" : ""}`}>
          {sortedMenuList.length > 0 ? (
            sortedMenuList.map((item) =>
              item.child_list.map((module) => {
                const isSelected = selectedModules.some(selected => selected.id === module.id);
                return (
                  <Col lg={3} className={`${isMobile ? "mb-2" : "mb-4"}`} key={module.id}>
                    <div
                      className={`d-flex justify-content-between align-items-center module-list ${isSelected ? "selected" : ""}`}
                      onClick={() => toggleModuleSelection(module.id)}
                    >
                      <h6 className={`m-0 ${isSelected ? "text-white" : "text-dark"}`}>{module.module_label}</h6>
                      {isSelected ? <FaMinus fill="#7CBB00" /> : <FaPlus fill="#7CBB00" />}
                    </div>
                  </Col>
                );
              })
            )
          ) : (
            <Col>
              <p>Loading modules...</p>
            </Col>
          )}
        </Row>

        {/* Price Calculation Section */}
        <Row>
          <Col lg={{ span: 3, offset: 9 }}>
            {selectedModules.length > 0 && (
              <div className={`mt-4 ${isMobile ? "text-end" : "text-start"} calculation-div`}>
                <div className='position-relative' style={{ paddingLeft: "9px" }}>
                  <span className="price-text">Total Price </span><span className='position-absolute' style={{ fontSize: "10px", top: "2px" }}>*</span>
                  {/* <h5>{currencysymbol} {finalPrice.toFixed(2)}</h5> */}
                  <h5>{formatCurrency(finalPrice, moduledata[0].child_list[0].currencycode)}</h5>
                </div>

                {activeButton === "annual" && (
                  // <div
                  //   className={`total-saving ${isMobile ? "justify-content-end" : "justify-content-start"} d-flex`}
                  // >
                  <div className={`total-saving`}>
                    <span className="discount-text">Total Savings:</span><br />
                    <span className="discount-price primary-text">
                      {formatCurrency(discount, moduledata[0].child_list[0].currencycode)}
                    </span>
                  </div>
                )}

              </div>
            )}
          </Col>
        </Row>
        <Row className='flex-row-reverse'>
          <Col lg={3} className={`${isMobile ? "text-end" : ""}`}>
            <button type="submit" className='common-btn pricingbtn  mt-4' onClick={datatransferclick}>Get Started</button>
          </Col>
          <Col lg={9} className={`${isMobile ? "text-end" : "d-flex align-items-center"}`}>
            <div>
              <p style={{ fontSize: "9px" }}>*The final price excludes all applicable taxes and charges</p>
            </div>
          </Col>

        </Row>


      </Container>

      {/* Background Images */}
      {/* <img
        src={isMobile ? ing2 : ing1}
        alt="Background"
        className={`img-fluid position-absolute ${isMobile ? "bottom-0" : "bottom-0"}`}
      /> */}
    </div>
  );
};

export default Pricing;
