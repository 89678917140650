import React from 'react'
import CommonBanner from './CommonBanner'
import img1 from '../images/detailImage/2760426.png'
import img2 from '../images/detailImage/18774.png'
import { TbReportMoney } from "react-icons/tb";
import { MdWebAsset } from "react-icons/md";
import { TbChartDots2 } from "react-icons/tb";
import { MdCollectionsBookmark } from "react-icons/md";
import AboutCommon from './AboutCommon';

const AccountComponent = () => {
	const data = {
		"heading": '<h2>Shopersbay Accounting Solutions: Streamlining <span class="primary-text">Business Finances</span></h2>',
		"description": "The shift to SaaS has transformed accounting, replacing manual bookkeeping with cloud-based solutions. Shopersbay offers automated, scalable accounting services with real-time data access, benefiting businesses and enhancing modern financial management",
		"image": img1,
		"bgImage": "",
		"rowReverse": "flex-row-reverse"
	}
	const data1 = {
        "heading": '<h2>Benefits of Using Shopersbay <span class="primary-text">Accounting Services</span></h2>',
        "description": "",
        "img": img2,
        "rowReverse": "",
        "list": [
            "Accounting Services",
            "Quick Customization of Courses",
            "Schedules of lessions and updates",
        ]
    }
	return (
		<div className='account-component'>
			<CommonBanner data={data} />
			<div className="section-padding">
				<div className="container">
					<div className="row clearfix justify-content-center">
						<div className="col-lg-10">
							<div className="heading-wrapper text-center with-separator">
								<h2 className="h1">Comprehensive <span className='primary-text'>Accounting Solutions</span> Offered by Shopersbay</h2>
								<div className="lead-text">
									<p>Shopersbay offers comprehensive accounting solutions, including automated bookkeeping, payroll, tax compliance, and financial reporting. Their cloud-based platform ensures accuracy, efficiency, security, and seamless integration for businesses of all sizes.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row clearfix">
						<div className="col-lg-6 col-md-6">
							<div className="features-block theme-four" data-aos="fade-up">
								<div className="inner-box">
									<div className="icon">
										<MdCollectionsBookmark size={70} />
									</div>
									<div className="text">
										<h4>General Ledger Management</h4>
										<p>Shopersbay automates bookkeeping, minimizes errors, integrates banking, categorizes expenses, generates reports, ensures compliance, and streamlines ledger management efficiently</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="features-block theme-four" data-aos="fade-up" data-wow-delay="0.2s">
								<div className="inner-box">
									<div className="icon">
										<TbChartDots2 size={70} />
									</div>
									<div className="text">
										<h4>Financial Reporting and Analytics</h4>
										<p>Accurate financial reporting aids decision-making with profit and loss statements, balance sheets, cash flow reports, and analytics for financial planning</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="features-block theme-four" data-aos="fade-up" data-wow-delay="0.2s">
								<div className="inner-box">
									<div className="icon">
										<MdWebAsset size={70} />
									</div>
									<div className="text">
										<h4>Inventory and Asset Management</h4>
										<p>Shopersbay accounting solutions track inventory and assets, monitor stock, assess depreciation, manage lifecycles, and ensure accurate valuation for cost control.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="features-block theme-four" data-aos="fade-up" data-wow-delay="0.4s">
								<div className="inner-box">
									<div className="icon">
										<TbReportMoney size={70} />
									</div>
									<div className="text">
										<h4>Expense and Budget Management</h4>
										<p>Shopersbay offers expense tracking and budgeting tools, integrating with corporate cards for automatic categorization, report generation, and financial goal management</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AboutCommon data={data1} />
{/* 
			<div className="light-bg section-padding">
				<div className="container">
					<div className="row clearfix">
						<div className="col-lg-6">
							<div className="heading-wrapper with-separator">
								<h2 className="h1">Benefits of Using Shopersbay <span className='primary-text'>Accounting Services</span></h2>
							</div>
							<div className="panel-group style-2" id="accordion" role="tablist" aria-multiselectable="true">
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="heading0">
										<h3 className="panel-title">
											<a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="true" aria-controls="collapse0">Powerful Study Pattern</a>
										</h3>
									</div>
									<div id="collapse0" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
										<div className="panel-body">
											<p>Nunc scelerisque bibendum felis eu aliquet. Quisque et neque in diam feugiat dapibus laoreet vitae risus. Aliquam in mauris ante. Nullam porta, metus at dictum consectetur, mi risus auctor eros, id interdum nisl velit et ante.</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="heading1">
										<h3 className="panel-title">
											<a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">Quick Customization of Courses</a>
										</h3>
									</div>
									<div id="collapse1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
										<div className="panel-body">
											<p>Nullam a nisl vitae sapien aliquet pellentesque. Mauris dui felis, sagittis non turpis quis, molestie facilisis quam. Ut ut augue tempor, tristique odio vitae, auctor erat.</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="heading2">
										<h3 className="panel-title">
											<a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">Schedules of lessions and updates</a>
										</h3>
									</div>
									<div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
										<div className="panel-body">
											<p>Suspendisse condimentum efficitur leo, vitae porttitor risus ornare nec. Maecenas eget lorem vestibulum, vestibulum nulla et, posuere nibh.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="image-wrapper">
								<img src={img2} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default AccountComponent
