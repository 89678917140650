import React from 'react'

const FeatureCommon = ({ data }) => {
    return (
        <div className="features-section benefits-section section-padding style-dark dark-bg gradient-heading-bg dc-two">
            <div className="container">
                <div className="row justify-content-center clearfix">
                    <div className="col-lg-8">
                        <div className="heading-wrapper text-center">
                            <div dangerouslySetInnerHTML={{ __html: data.heading }} />
                            <div className="lead-text">
                                {data.description}
                            </div>
                        </div>
                        <div className="empty-space-60 clearfix"></div>
                    </div>
                </div>
                <div className="row clearfix">
                    {
                        data.listdata.map((ele, i) => {
                            return (
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <div className="features-block theme-two" data-aos="fade-up">
                                        <div className="inner-box">
                                            <div className="text">
                                                <h5>{ele.title}</h5>
                                                <p>{ele.discrp}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FeatureCommon
