import React from 'react'
import CommonBanner from './CommonBanner'
import CommonKeyFeature from './CommonKeyFeature'
import img1 from '../images/detailImage/1307840.png'
import { FaUserFriends } from "react-icons/fa";
import { WiCloud } from "react-icons/wi";
import { MdOutlineInventory2 } from "react-icons/md";
import { PiStorefront } from "react-icons/pi";
import { TbReport } from "react-icons/tb";
import { GrTransaction } from "react-icons/gr";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const PosComponent = () => {
	var settings = {
		dots: false,
		arrow: false,
		infinite: false,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 600,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					dots: true,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	const data = {
		"heading": '<h2>Shopersbay POS: A Comprehensive Point of Sale Solution</h2>',
		"description": "Shopersbay POS is a robust Point of Sale system integrating hardware and software to streamline sales, inventory tracking, and customer management while offering seamless checkout and business application integration across multiple industries",
		"image": img1,
		"bgImage": "",
		"rowReverse": "flex-row-reverse"
	}
	const data1 = {
		"heading": '<h2>Key Features of <span class="primary-text">Shopersbay POS</span></h2>',
		"description": "",
		"listData": [
			{
				"title": "User-Friendly Interface",
				"icon": <FaUserFriends size={80} />,
				"descrp": "Shopersbay POS features an intuitive interface, touch-screen compatibility, and a customizable dashboard, enabling quick transactions and tailored business operations"
			},
			{
				"title": "Cloud-Based Technology",
				"icon": <WiCloud size={80} />,
				"descrp": "Shopersbay POS's cloud-based system enables businesses to access sales data, manage inventory, and monitor transactions remotely for seamless multi-location operations"
			},
			{
				"title": "Inventory Management",
				"icon": <MdOutlineInventory2 size={80} />,
				"descrp": "Shopersbay POS updates stock levels in real time, preventing overstocking or stockouts, ensuring businesses maintain optimal inventory efficiently and accurately"
			},
			{
				"title": "Multi-Store Management",
				"icon": <PiStorefront size={80} />,
				"descrp": "For businesses with multiple locations, Shopersbay POS provides centralized control. Owners can manage pricing, promotions, and inventory across all stores from a single platform"
			},
			{
				"title": "Secure and Compliant Transactions",
				"icon": <GrTransaction size={80} />,
				"descrp": "Shopersbay POS ensures that transactions are secure with end-to-end encryption and compliance with industry standards such as PCI DSS. This minimizes the risk of fraud and data breaches."
			},
			{
				"title": "Sales and Analytics Reports",
				"icon": <TbReport size={80} />,
				"descrp": "With detailed sales and performance analytics, Shopersbay POS helps businesses make data-driven decisions. Reports on revenue, top-selling products, and customer trends provide valuable insights"
			},
		]
	}
	return (
		<div className='pos-component'>
			<CommonBanner data={data} />
			<CommonKeyFeature data={data1} />
			<div class="section-padding light-gradient-bg">
				<div class="container">
					<div class="row clearfix justify-content-center">
						<div class="col-lg-8">
							<div class="heading-wrapper text-center">
								<h2 ><span className='primary-text'>Industries</span> That Benefit from Shopersbay POS</h2>
								{/* <div class="lead-text">
									<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque.</h6>
								</div> */}
							</div>
						</div>
					</div>
					<div class="row clearfix">
						<div class="col-lg-12">
							<Slider {...settings} className='timeline-slider'>
								<div class="timeline-item">
									<div class="timeline-wrapper">
										<div class="step-number">
											<span>1</span>
										</div>
										<div class="content">
											<h5>Retail Stores</h5>
											<p>Efficiently manages sales and inventory across multiple retail stores with ease</p>
										</div>
									</div>
								</div>
								<div class="timeline-item">
									<div class="timeline-wrapper">
										<div class="step-number">
											<span>2</span>
										</div>
										<div class="content">
											<h5>Grocery Stores</h5>
											<p>Fast scanning, inventory updates, and loyalty programs enhance grocery efficiency</p>
										</div>
									</div>
								</div>
								<div class="timeline-item">
									<div class="timeline-wrapper">
										<div class="step-number">
											<span>3</span>
										</div>
										<div class="content">
											<h5>E-Commerce Businesses</h5>
											<p>Syncs inventory and sales for seamless online-offline consistency</p>
										</div>
									</div>
								</div>
								<div class="timeline-item">
									<div class="timeline-wrapper">
										<div class="step-number">
											<span>4</span>
										</div>
										<div class="content">
											<h5>Restaurants and Cafés</h5>
											<p>Shopersbay POS offers table management, order tracking, and split billing</p>
										</div>
									</div>
								</div>
							</Slider>
							{/* <div class="timeline timeline-slider">
								
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PosComponent
