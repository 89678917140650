import React, { useState } from 'react'
import CommonBanner from './CommonBanner'
import bg from '../images/detailImage/bg-4.png'
import img1 from '../images/detailImage/5644447.png'
import img2 from '../images/detailImage/49408025_9212305.png'
import img3 from '../images/detailImage/5604577_56793.png'
import img4 from '../images/detailImage/1537263_4590.png'
import img5 from '../images/detailImage/12084754_20943899.png'
import { FaCheck } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";
import { LuShoppingCart } from "react-icons/lu";
import { MdInventory } from "react-icons/md";
import { RiSeoLine } from "react-icons/ri";
import { TbReport } from "react-icons/tb";
import { MdOutlineSecurity } from "react-icons/md";
import CommonFeatureComponent from './CommonFeatureComponent'
import { useMediaQuery } from 'react-responsive'


const EcommerseComponent = () => {
    const [activeTab, setActiveTab] = useState("WideProductRange");
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const data = {
        "heading": '<h2>E-commerce Solutions by Shopersbay</h2>',
        "description": "Shopersbay empowers businesses with seamless e-commerce solutions, including web development, payment integration, and digital marketing, to establish, manage, and grow their online stores effectively",
        "image": img1,
        "bgImage": bg,
        "rowReverse": "flex-row-reverse"
    }
    const listData = [
        {
            "icon": <CgWebsite size={60} />,
            "title": "Website Design and Development"
        },
        {
            "icon": <LuShoppingCart size={60} />,
            "title": "Shopping Cart and Payment Integration"
        },
        {
            "icon": <MdInventory size={60} />,
            "title": "Product and Inventory Management"
        },
        {
            "icon": <RiSeoLine size={60} />,
            "title": "Digital Marketing and SEO"
        },
        {
            "icon": <MdOutlineSecurity size={60} />,
            "title": "Security and Compliance"
        },
        {
            "icon": <TbReport size={60} />,
            "title": "Analytics and Reporting"
        }
    ]
    const data1 = {
        "heading": '<h2>Customer <span class="primary-text">Experience</span> and <span class="primary-text">Satisfaction</span> </h2>',
        "description": "Shopersbay places immense importance on customer satisfaction. From user-friendly interfaces to responsive support teams, every aspect of the platform is designed to enhance the shopping experience.",
        "list": [
            {
                "icon": "",
                "title": "User-Friendly Interface",
                "descpt": "The platform is designed with a clean and intuitive interface, making it easy for users of all technical backgrounds to navigate and manage their stores.",
            },
            {
                "icon": "",
                "title": "Hassle-Free Returns and Refunds",
                "descpt": "ShopersBay understands that every business has unique needs. The admin panel is highly customizable, allowing store owners to configure settings and features according to their requirements.",
            },
            {
                "icon": "",
                "title": "Customer Support and Engagement",
                "descpt": "The software seamlessly integrates with third-party applications such as payment gateways, shipping providers, and marketing tools, ensuring a unified e-commerce ecosystem.",
            }
        ]
    }
    return (
        <div className='ecommerse-component'>
            <CommonBanner data={data} />
            <div class="core-service section-padding dc-two">
                <div class="container">
                    <div class="row clearfix">
                        {listData.map((ele, i) => {
                            return (
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                    <div class="features-block theme-three" data-aos="fade-up">
                                        <div class="inner-box">
                                            <div class="icon">
                                                {ele.icon}
                                            </div>
                                            <div class="text">
                                                <h5>{ele.title}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    
                    {/* <div class="row clearfix align-items-center flex-row-reverse">
                        <div class="col-lg-7 text-center">
                            <div class="image-wrapper">
                                <div class="circle-background-animation">
                                    <img src="images/default-color/Quick-Easy-Process-img.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="heading-wrapper with-separator">
                                <h2 class="h1"><span>Quick & Easy</span> Process</h2>
                            </div>

                            <div class="text-wrapper">
                                <p class="lead-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales dictum viverra.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales dictum viverra. Nam gravida dignissim eros. Vivamus congue erat ante, volutpat dictum neque dignissim eget. </p>
                            </div>
                            <div class="counter-wrapper d-flex">
                                <div class="counter-block">
                                    <div class="counter">
                                        <span class="counter-number-prefix"></span>
                                        <span class="counter-number" data-speed="3500" data-stop="80">0</span>
                                        <span class="counter-number-after">k</span>
                                        <span class="counter-number-suffix">+</span>
                                    </div>
                                    <div class="counter-title">Monthly business account transaction limit</div>
                                </div>
                                <div class="counter-block">
                                    <div class="counter">
                                        <span class="counter-number-prefix"></span>
                                        <span class="counter-number" data-speed="3500" data-stop="25">0</span>
                                        <span class="counter-number-after">k</span>
                                        <span class="counter-number-suffix">+</span>
                                    </div>
                                    <div class="counter-title">Business account transaction limit</div>
                                </div>

                            </div>
                            <div class="empty-space-30"></div>
                            <div class="btn-wrapper">
                                <a class="btn link-btn" href="#">Learn more about transaction</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <CommonFeatureComponent data={data1} />
            {/* <div class="how-works section-padding style-dark dark-bg gradient-heading-bg">
                <div class="container">
                    <div class="row clearfix justify-content-center">
                        <div class="col-lg-8">
                            <div class="heading-wrapper text-center">
                                <h2 class="h1">How does it <span>works</span></h2>
                                <div class="lead-text">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="empty-space-60 clearfix"></div>
                            <div class="how-it-work-wrapper three-step">
                                <div class="item step-1">
                                    <div class="step-number">
                                        <span>01.</span>
                                    </div>
                                    <h3 class="step-title">Add Customers</h3>
                                    <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                </div>
                                <div class="item step-2">
                                    <div class="step-number">
                                        <span>02.</span>
                                    </div>
                                    <h3 class="step-title">Schedule Payments</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida.</p>
                                </div>
                                <div class="item step-3">
                                    <div class="step-number">
                                        <span>03.</span>
                                    </div>
                                    <h3 class="step-title">Collect on due Dates</h3>
                                    <p>Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque.</p>
                                </div>
                            </div>
                            <div class="empty-space-30 clearfix"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div class="btn-wrapper">
                                <a href="#" class="btn btn-primary btn-light">Sign up Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="services-section section-padding">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-10">
                            <div className="heading-wrapper with-separator">
                                <h2 >Business Model and Offerings</h2>
                                <div className="lead-text">
                                    <h6>Shopersbay operates on a multi-faceted business model that includes direct sales, third-party marketplace services, and subscription-based memberships.</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Tab Navigation */}
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="st-tab-btn">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "WideProductRange" ? "active" : ""}`} onClick={() => setActiveTab("WideProductRange")}>
                                            Wide Product Range
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "ThirdPartyMarketplace" ? "active" : ""}`} onClick={() => setActiveTab("ThirdPartyMarketplace")}>
                                            Third-Party Marketplace
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "SubscriptionServices" ? "active" : ""}`} onClick={() => setActiveTab("SubscriptionServices")}>
                                            Subscription Services
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "B2BServices" ? "active" : ""}`} onClick={() => setActiveTab("B2BServices")}>
                                            B2B Services
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            {/* Tab Content */}
                            <div className="st-tab-content">
                                <div className={`tab-pane ${activeTab === "WideProductRange" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img src={img2} alt="Wide Product Range" className='img-fluid' />
                                        </div>
                                        <div className="col-md-7">
                                            <h5>Wide Product Range</h5>
                                            <ul className="list-style-disc">
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Electronics and gadgets</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Fashion and apparel</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} />Home and kitchen essentials</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Health and beauty products</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Groceries and household items</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Books, toys, and entertainment</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === "ThirdPartyMarketplace" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img src={img3} alt="Third-Party Marketplace" className='img-fluid' />
                                        </div>
                                        <div className="col-md-7">
                                            <h5>Third-Party Marketplace</h5>
                                            <p>Shopersbay allows third-party sellers to list their products, enabling a broader selection of goods.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === "SubscriptionServices" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img src={img4} alt="Subscription Services" className='img-fluid' />
                                        </div>
                                        <div className="col-md-7">
                                            <h5>Subscription Services</h5>
                                            <ul className="list-style-disc">
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Free express shipping</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Exclusive deals and discounts</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Early access to sales events</li>
                                                <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Personalized shopping recommendations</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === "B2BServices" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img src={img5} alt="B2B Services" className='img-fluid' />
                                        </div>
                                        <div className="col-md-7">
                                            <h5>B2B Services</h5>
                                            <p>Shopersbay offers bulk purchasing, wholesale pricing, and enterprise solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="services-section section-padding">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-10">
                            <div className="heading-wrapper with-separator">
                                <h2 className="h1">Business Model and Offerings</h2>
                                <div className="lead-text">
                                    <p>Shopersbay operates on a multi-faceted business model that includes direct sales, third-party marketplace services, and subscription-based memberships. The platform offers:</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="st-tab-btn">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#WideProductRange" role="tab">Wide Product Range</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#ThirdPartyMarketplace" role="tab">Third-Party Marketplace</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#SubscriptionServices" role="tab">Subscription Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#B2BServices" role="tab">B2B Services</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="st-tab-content">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="WideProductRange" role="tabpanel">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img src={img2} alt="" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="text-wrapper">
                                                    <h4>Wide Product Range</h4>
                                                    <p>Shopersbay provides an extensive catalog of products, including:</p>
                                                    <ul className="list-style-one">
                                                        <li>Electronics and gadgets.</li>
                                                        <li>Fashion and apparel</li>
                                                        <li>Home and kitchen essentials</li>
                                                        <li>Health and beauty products</li>
                                                        <li>Groceries and household items</li>
                                                        <li>Books, toys, and entertainment</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="ThirdPartyMarketplace" role="tabpanel">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img src={img3} alt="" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="text-wrapper">
                                                    <h4>Third-Party Marketplace</h4>
                                                    <p>In addition to selling its own inventory, Shopersbay allows third-party sellers to list their products. This marketplace model enables customers to access a broader selection of goods while giving sellers a platform to reach a wider audience.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="SubscriptionServices" role="tabpanel">
                                        <div className="row">
                                            <div className="col-md-5">
                                            <img src={img4} alt="" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="text-wrapper">
                                                <h4>Subscription Services</h4>
                                                <p>Shopersbay offers a premium membership program that provides benefits such as:</p>
                                                <ul className="list-style-one">
                                                    <li>Free express shipping</li>
                                                    <li>Exclusive deals and discounts</li>
                                                    <li>Early access to sales events</li>
                                                    <li>Personalized shopping recommendations</li>
                                                </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="B2BServices" role="tabpanel">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img src={img5} alt="" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="text-wrapper">
                                                    <h4>B2B Services</h4>
                                                    <p>Shopersbay also caters to businesses by offering bulk purchasing options, wholesale pricing, and enterprise solutions for companies looking to streamline their procurement process.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default EcommerseComponent
