import React from 'react'
import * as common from '../components'

const EcommerseDetail = () => {
  return (
    <>
        <common.Header />
        <common.EcommerseComponent />
        <common.Pricing />
        <common.Faqs />
        <common.Ready />
        <common.Footer />
    </>
  )
}

export default EcommerseDetail
