import React from 'react'
import * as common from '../components'

const CmsDetail = () => {
  return (
    <>
            <common.Header />
            <common.CmsComponent />
            <common.Pricing />
            <common.Faqs />
            <common.Ready />
            <common.Footer />
        </>
  )
}

export default CmsDetail
