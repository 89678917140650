import React, { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';
import * as view from './view';
import * as ROUTES from './constant/routes';
import Apis from "./services/Apis";
import AOS from "aos";
import WOW from 'wowjs';
import "aos/dist/aos.css"; // Import the CSS file

function App() {
  const { getIp } = Apis();
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('selectedIds');
    getIp()
    // const targetWindow = window.open('https://login.shopersbay.com/'); // Open the other website in a new window

    // // Wait for the target window to load before sending the message
    // if (targetWindow) {
    //   targetWindow.addEventListener('load', () => {
    //     targetWindow.postMessage({ data }, 'https://login.shopersbay.com/'); // Send the data
    //   });
    // }

    // // Clean up on component unmount
    // return () => {
    //   if (targetWindow) {
    //     targetWindow.close();
    //   }
    // };
  }, []); // Empty dependency array to run once after the component mounts

  return (
    <Router>
      <Suspense>
        <Routes>
          <Route
            path={ROUTES.Home}
            element={
              <ScrollToTop>
                <view.Home />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.Static}
            element={
              <ScrollToTop>
                <view.Static />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.Detail}
            element={
              <ScrollToTop>
                <view.Detail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.EcommerseDetail}
            element={
              <ScrollToTop>
                <view.EcommerseDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.CmsDetail}
            element={
              <ScrollToTop>
                <view.CmsDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.HrisDetail}
            element={
              <ScrollToTop>
                <view.HrisDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.PosDetail}
            element={
              <ScrollToTop>
                <view.PosDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.AssetDetail}
            element={
              <ScrollToTop>
                <view.AssetDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.AccountDetail}
            element={
              <ScrollToTop>
                <view.AccountDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.AdminDetail}
            element={
              <ScrollToTop>
                <view.AdminDetail />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.CrmDetail}
            element={
              <ScrollToTop>
                <view.CrmDetail />
              </ScrollToTop>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
