import React from 'react'

const ServiceCommon = ({ data }) => {
    return (
        <div class="services-section section-padding light-bg">
            <div class="container">
                <div class="row clearfix justify-content-center">
                    <div class="col-lg-10">
                        <div class="heading-wrapper text-center with-separator">
                            <div dangerouslySetInnerHTML={{ __html: data.heading }} />
                            <div class="lead-text">
                                <h6>{data.description}</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row clearfix" data-aos="fade-up">
                    {data.list.map((ele, i) => {
                        return (
                            <div class={`${data.colsize}`} key={i}>
                                <div class="common-icon-box theme-one ">
                                    <div class="icon">
                                        {ele.icons}
                                    </div>
                                    <div class="text">
                                        <h5>{ele.title}</h5>
                                        <p>{ele.discrp}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ServiceCommon
