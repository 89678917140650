import React from 'react'
import * as common from '../components'

const HrisDetail = () => {
  return (
    <>
        <common.Header />
        <common.HrisComponent />
        <common.Pricing />
        <common.Faqs />
        <common.Ready />
        <common.Footer />
    </>
  )
}

export default HrisDetail
