import React from 'react'
import bg from '../images/detailImage/bg-3.png'
import bg1 from '../images/detailImage/half-circle-bg.png'
import img1 from '../images/detailImage/ss-1.jpg'
import img2 from '../images/detailImage/ss-2.jpg'
import img3 from '../images/detailImage/ss-3.jpg'
import img4 from '../images/detailImage/laptop-screen.png'
import img5 from '../images/detailImage/18770.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutCommon from './AboutCommon'
import ServiceCommon from './ServiceCommon'
import FeatureCommon from './FeatureCommon'
import { BiTask } from "react-icons/bi";
import { LiaUsersSolid } from "react-icons/lia";
import { MdOutlineSpatialTracking } from "react-icons/md";

const CrmComponent = () => {
	var settings = {
		dots: false,
		arrow: false,
		infinite: true,
		speed: 600,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: true,
		cssEase: 'linear',
	};

	const data1 = {
		"heading": '<h2>Manage all your work from <span class="primary-text">One Place</span></h2>',
		"description": "",
		"img": img5,
		"rowReverse": "",
		"list": [
			"Centralized Data Management",
			"Task & Workflow Automation",
			"Seamless Communication",
			"Sales & Lead Management",
			"Marketing Automation",
			"Analytics & Reporting",
			"Customer Support & Service Management",
			"Multi-Device & Cloud Accessibility",
			"Integration with Other Tools",
			"Scalability & Customization",
		]
	}
	const data = {
		"heading": '<h2>Focus on getting things done with <span class="primary-text">Shopersbay</span></h2>',
		"description": "Businesses need Shopersbay for efficient asset management, cost optimization, productivity enhancement, risk management, and strategic decision-making with data-driven insights.",
		"colsize": "col-lg-4 col-12",
		"list": [
			{
				"title": "Improve Efficiency",
				"icons": <BiTask size={70} />,
				"discrp": "Improves efficiency by automating tasks, organizing data, and enhancing communication"
			},
			{
				"title": "Improve Quality",
				"icons": <LiaUsersSolid size={70} />,
				"discrp": "Improves quality by enhancing customer interactions, streamlining processes, and optimizing data management"
			},
			{
				"title": "Improve Accountability",
				"icons": <MdOutlineSpatialTracking size={70} />,
				"discrp": "Improves accountability by tracking interactions, responsibilities, and performance transparently"
			}
		]
	}
	const data2 = {
		"heading":'<h2>We offer Powerfull  <span class="primary-text">features</span></h2>',
		"bgImage" : bg1,
		"description" : "",
		"listdata": [
			{
				"title": "Time Tracking",
				"discrp":"",
			},
			{
				"title": "Time Billing",
				"discrp":"",
			},
			{
				"title": "Email Tasks in",
				"discrp":"",
			},
			{
				"title": "Calendar Sync",
				"discrp":"",
			},
			{
				"title": "Mobile Application",
				"discrp":"",
			},
			{
				"title": "Access Control",
				"discrp":"",
			},
			{
				"title": "Snooze Tasks",
				"discrp":"",
			},
			{
				"title": "Track Conversations",
				"discrp":"",
			},
			{
				"title": "Out of Office",
				"discrp":"",
			},
		]
	}
	
	return (
		<div className='crm-component'>

			<div className="page-header section-padding style-dark full-height dc-two pb-0" style={{ backgroundImage: `url(${bg})` }}>
				<div className="container">
					<div className="row justify-content-center text-center">
						<div className="col-lg-7 col-md-12">
							<div className="heading-wrapper" data-wow-delay="0.8s" data-aos="fade-down">
								<h1 className='text-white'>We Deliver the Software you need</h1>
								<div className="lead-text">
									<h6>Integrated CRM, project management, time tracking and billing for freelancers, consultants and teams.</h6>
								</div>
							</div>
							<div className="btn-wrapper" data-aos="fade-in" data-wow-delay="1.2s">
								<a className="btn btn-primary btn-light " href="#">try to free</a>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="ss-wrapper" data-wow-delay="1.5s" data-aos="fade-up">
								<img className="laptop-img-bg img-fluid" src={img4} alt="" />
								<Slider {...settings} className='screenshot-slider'>
									<div className="item">
										<img src={img1} alt="" className='img-fluid' />
									</div>
									<div className="item">
										<img src={img2} alt="" className='img-fluid' />
									</div>
									<div className="item">
										<img src={img3} alt="" className='img-fluid' />
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AboutCommon data={data1} />
			<ServiceCommon data={data} />
			<FeatureCommon data={data2} />
		</div>
	)
}

export default CrmComponent
