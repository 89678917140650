export const Home = '/';
export const Static = '/policies/:slug';
export const Detail = '/detail/:slug';
export const EcommerseDetail = '/detail/eccommerse-solutions';
export const CmsDetail = '/detail/cms';
export const CrmDetail = '/detail/crm';
export const HrisDetail = '/detail/hris';
export const PosDetail = '/detail/pos';
export const AssetDetail = '/detail/asset-management';
export const AccountDetail = '/detail/account';
export const AdminDetail = '/detail/admin-panel';
