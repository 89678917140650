import React from 'react'
import img1 from '../images/detailImage/asset.png'
import img2 from '../images/detailImage/asset-bg.png'
import img3 from '../images/detailImage/3907921.png'
import ServiceCommon from './ServiceCommon'
import AboutCommon from './AboutCommon'
import CommonKeyFeature from './CommonKeyFeature'
import CommonBanner from './CommonBanner'
import { GiMoneyStack } from "react-icons/gi";
import { CgPerformance } from "react-icons/cg";
import { MdWebAsset, MdModelTraining, MdOutlineSecurity  } from "react-icons/md";
import { AiOutlineAudit, AiOutlineStock, AiOutlineDatabase } from "react-icons/ai";
import { TbBuildingWarehouse } from "react-icons/tb";
import { GrTechnology } from "react-icons/gr";

const AssetComponent = () => {
    const data = {
        "heading": '<h2>Why Businesses Need <span class="primary-text">Shopersbay</span></h2>',
        "description": "Businesses need Shopersbay for efficient asset management, cost optimization, productivity enhancement, risk management, and strategic decision-making with data-driven insights.",
        "colsize": "col-lg-3 col-12",
        "list": [
            {
                "title": "Cost Efficiency and Optimization",
                "icons": <GiMoneyStack size={70} />,
                "discrp": "Optimize assets, reduce costs, prevent waste, and enhance productivity"
            },
            {
                "title": "Enhanced Productivity and Performance",
                "icons": <CgPerformance size={70} />,
                "discrp": "Minimizes downtime, boosts productivity, prevents failures, ensures smooth operations"
            },
            {
                "title": "Strategic Decision Making",
                "icons": <MdWebAsset size={70} />,
                "discrp": "Data-driven insights for strategic asset management and financial planning"
            },
            {
                "title": "Risk Management and Compliance",
                "icons": <AiOutlineAudit size={70} />,
                "discrp": "Ensures safety, compliance, sustainability, integrity, and supports audits efficiently"
            }
        ]
    }
    const data1 = {
        "heading": '<h2>Key <span class="primary-text">Services Offered</span> by Shopersbay</h2>',
        "description": "",
        "img": img3,
        "rowReverse": "",
        "list": [
            "Asset Tracking and Inventory Management",
            "Predictive and Preventive Maintenance",
            "Financial and Lifecycle Management",
            "Compliance and Risk Management",
            "Digitalization and Automation Solutions",
        ]
    }
    const data2 = {
        "heading": '<h2>Key <span class="primary-text">Services Offered</span> by Shopersbay</h2>',
        "description": "",
        "listData": [
            {
                "title": "Data Management Services",
                "icon": <AiOutlineDatabase size={80} />,
                "descrp": "Shopersbay POS features an intuitive interface, touch-screen compatibility, and a customizable dashboard, enabling quick transactions and tailored business operations"
            },
            {
                "title": "Data Warehouse Services",
                "icon": <TbBuildingWarehouse size={80} />,
                "descrp": "Shopersbay POS's cloud-based system enables businesses to access sales data, manage inventory, and monitor transactions remotely for seamless multi-location operations"
            },
            {
                "title": "Data Quality Assurance",
                "icon": <AiOutlineStock size={80} />,
                "descrp": "Shopersbay POS updates stock levels in real time, preventing overstocking or stockouts, ensuring businesses maintain optimal inventory efficiently and accurately"
            },
            {
                "title": "Data Security",
                "icon": <MdOutlineSecurity  size={80} />,
                "descrp": "For businesses with multiple locations, Shopersbay POS provides centralized control. Owners can manage pricing, promotions, and inventory across all stores from a single platform"
            },
            {
                "title": "Training and Onboarding",
                "icon": <MdModelTraining size={80} />,
                "descrp": "Shopersbay POS ensures that transactions are secure with end-to-end encryption and compliance with industry standards such as PCI DSS. This minimizes the risk of fraud and data breaches."
            },
            {
                "title": "Technology and Tools",
                "icon": <GrTechnology size={80} />,
                "descrp": "With detailed sales and performance analytics, Shopersbay POS helps businesses make data-driven decisions. Reports on revenue, top-selling products, and customer trends provide valuable insights"
            },
        ]
    }
    const data3 = {
        "heading": '<h1>Accelerate growth with data-driven insights and technology</h1>',
		"description": "Optimize your operations, enhance client engagement, and discover new growth avenues with tailored solutions and services designed for asset managers",
		"image": img1,
		"bgImage": img2,
		"rowReverse": "flex-row-reverse"
    }
    return (
        <div className='asset-component'>
            {/* <div class="section-padding full-height dc-ten" style={{ backgroundImage: `url(${img2})` }}>
                <div class="container">
                    <div class="row align-items-center flex-row-reverse">
                        <div class="col-lg-6">
                            <div class="image-wrapper" data-aos="fade-up" data-wow-delay="0.2s">
                                <img src={img1} alt="" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="heading-wrapper with-separator" data-aos="fade-left" data-wow-delay="0.2s">
                                <h1>Accelerate growth with data-driven insights and technology</h1>
                            </div>
                            <div class="text-wrapper" data-aos="fade-left" data-wow-delay="0.2s">
                                <h6>Optimize your operations, enhance client engagement, and discover new growth avenues with tailored solutions and services designed for asset managers.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <CommonBanner data={data3} />
            <ServiceCommon data={data} />
            <AboutCommon data={data1} />
            <CommonKeyFeature data={data2} />
        </div>
    )
}

export default AssetComponent
