import React from 'react'

const CommonKeyFeature = ({data}) => {
  return (
    <div id="" className="page-wrapperbg-light" data-aos="fade-up">
        <div className="service-section section-padding">
            <div className="container">
                <div className="row clearfix justify-content-center">
                    <div className="col-lg-10">
                        <div className="heading-wrapper text-center with-separator">
                            <div dangerouslySetInnerHTML={{ __html: data.heading }} />
                            {data.description != "" && 
                            <div className="lead-text">
                                <p>{data.description}</p>
                            </div>
                            }
                        </div>
                    </div>
                    
                </div>
                <div className="row clearfix no-gutters dc-features-group mt-lg-5">
                    {data.listData.map((ele, i)=>{
                        return(
                        <div className="col-lg-4 col-md-6 dc-features-item" key={i}>
                            <div className='dc-features-item-div'>
                                <div className="dc-features-item-front">
                                    <div className="inner-box">
                                        <div className="icon">
                                            {ele.icon}
                                        </div>
                                        <h5 className="dc-features-title">{ele.title}</h5>
                                    </div>
                                </div>
                                <div className="dc-features-item-hover">
                                    <div className="inner-box">
                                        <h5 className="dc-features-title">{ele.title}</h5>
                                        <p>{ele.descrp}</p>
                                    </div>
                                </div>
                                </div>
                        </div>
                        )
                    })}
                    

                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CommonKeyFeature
