import React from 'react'
import img1 from '../images/detailImage/bg-2.jpg'

const ContactCommon = () => {
  return (
    <div className="cta-section section-padding style-dark" style={{backgroundImage: `url(${img1})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: 'center bottom', backgroundSize: 'cover' }} >
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="call-to-action-content i-text-center">
                        <h2 className="text-white">Do you have any questions? <span className="special-fonts">We are happy to help you.</span></h2>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="call-to-action-btn text-right i-text-center">
                        <a href="#" className="btn btn-primary btn-light btn-large">Contact Us Now!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactCommon
