import React from 'react'
import img1 from '../images/erp.png'
import img2 from '../images/choose-cms.png'
import img3 from '../images/affordable_price.png'
import img4 from '../images/custom_solution.png'
import img5 from '../images/seamless_integration.png'
import img6 from '../images/support.png'
import mobBg from '../images/mob_banner_bg.webp'
import deskBg from '../images/banner-bg.webp'
import { IoTimerOutline } from "react-icons/io5";
import { MdOutlineAnalytics } from "react-icons/md";
import { FaCloudscale } from "react-icons/fa6";
import { FaChartLine } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive'
import CommonBanner from './CommonBanner'
import CommonFeatureComponent from './CommonFeatureComponent'
import AboutCommon from './AboutCommon'
import { IoMdCash } from "react-icons/io";
import { BsBoxSeam } from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";

const CmsComponent = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const data = {
        "heading": '<h1>ShopersBay: <span class="primary-text">Powering Digital Success</span> with CMS</h1>',
        "description": "A robust CMS streamlines product updates, enhances SEO, ensures smooth navigation, integrates essential tools, supports scalability, and strengthens security, making it vital for efficient content management and sustained e-commerce growth",
        "image": img1,
        "bgImage": deskBg,
        "rowReverse": "flex-row-reverse"
    }
    const data1 = {
        "heading": '<h2>Why <span class="primary-text">Businesses</span> Need ShopersBay CMS?</h2>',
        "description": "ShopersBay CMS is designed to simplify content management for businesses of all sizes. With an intuitive interface, robust features, and seamless integration options, it enables users to manage their website without technical expertise. Key benefits include:",
        "list": [
            {
                "icon": <IoTimerOutline size={70} />,
                "title": "User-Friendly Interface",
                "descpt": "Enables real-time content updates without developers",
            },
            {
                "icon": <MdOutlineAnalytics size={70} />,
                "title": "Enhances Website Functionality",
                "descpt": "Boosts functionality with plugins, analytics, and automation.",
            },
            {
                "icon": <FaCloudscale size={70} />,
                "title": "Scalable for Future Growth",
                "descpt": "Enables seamless growth, new features, and scalable performance.",
            },
            {
                "icon": <FaChartLine size={70} />,
                "title": "Competitive Advantage",
                "descpt": "A well-managed CMS drives success with updates, SEO, engagement, and omnichannel marketing",
            }
        ],
        "rowReverse": "flex-row-reverse"
    }
    const data2 = {
        "heading": "<h2>Key Features of <span class='primary-text'>ShopersBay CMS</span></h2>",
        "description": "",
        "img": img2,
        "rowReverse": "flex-row-reverse",
        "bg_color":"#F7FCF4",
        "list": [
            "Custom CMS Development",
            "User-Friendly Interface",
            "SEO and Digital Marketing Tools",
            "Multi-User Collaboration & Role-Based Access",
            "E-Commerce Integration",
            "Third-Party Integrations",
            "Security and Performance Optimization",
            "Mobile-Friendly and Responsive Design",
            "Content Scheduling & Version Control",
            "Ongoing Support & Maintenance",
        ]
    }

    return (
        <div className='cms-component'>
            <CommonBanner data={data} />
            <CommonFeatureComponent data={data1} />
            <AboutCommon data={data2}/>
            <div className="cms-feature section-padding">
                <div className="container">
                    <div className="row clearfix justify-content-center">
                        <div className="col-lg-8">
                            <div className="heading-wrapper with-separator text-center">
                                <h2>Choosing <span className='primary-text'>ShopersBay CMS</span> for Your Business </h2>
                                <div className="lead-text">
                                    <h6>Selecting the right CMS provider is critical for business success. ShopersBay offers a comprehensive suite of CMS services tailored to meet business objectives.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="features-block theme-three" data-aos="fade-up">
                                <div className="inner-box">
                                    <div className="icon">
                                        <LiaMoneyBillWaveSolid size={90} />
                                    </div>
                                    <div className="text">
                                        <h5>Custom Solutions</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="features-block theme-three" data-aos="fade-up" data-wow-delay="0.2s">
                                <div className="inner-box">
                                    <div className="icon">
                                       <IoMdCash size={90} />
                                    </div>
                                    <div className="text">
                                        <h5>Affordable Pricing</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="features-block theme-three" data-aos="fade-up" data-wow-delay="0.4s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <BsBoxSeam size={90} />
                                    </div>
                                    <div className="text">
                                        <h5>Seamless Integrations</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="features-block theme-three" data-aos="fade-up" data-wow-delay="0.6s">
                                <div className="inner-box">
                                    <div className="icon">
                                        <MdSupportAgent size={90} />
                                    </div>
                                    <div className="text">
                                        <h5>Exceptional Customer Support</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsComponent
