import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Apis from '../../services/Apis';
import { setToggleFalse } from '../slice/changeState'

const { getFaqs, getModules, saveContactFormdata, getImages, getNavigationlist, getIp } = Apis()


export const fetchIp = createAsyncThunk("fetchIp", async () => {
    const response = await getIp();
    return response.data;
});

export const fetchFaqsData = createAsyncThunk("fetchFaqsData", async () => {
    const response = await getFaqs();
    return response.data;
});

export const fetchModuleData = createAsyncThunk("fetchModuleData", async (input_data) => {
    const response = await getModules(input_data);
    return {data: response.data, duration: input_data.duration};
});
export const fetchImagesData = createAsyncThunk("fetchImagesData", async (input_data) => {
    const response = await getImages(input_data);
    return {data: response.data};
});
export const fetchNavigationData = createAsyncThunk("fetchNavigationData", async (input_data) => {
    const response = await getNavigationlist(input_data);
    return {data: response.data};
});

export const saveContact = createAsyncThunk("saveContact", async (input_data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
        
        const response = await saveContactFormdata(input_data);
        if (response.data.status === 'success') {
            dispatch(setToggleFalse()); // Dispatch the action
        }
        return response.data;
    } catch (error) {
        throw error;
    }
});

const masterSlice = createSlice({
    name: "master",
    initialState: {
        isLoading: false,
        faqdata: null,
        moduledata: null,
        selectedModules: [],
        calculateddata: {},
        contactData: null,
        annualDiscount: 0,
        imagesList: null,
        navigationList: null,
        ipData: null,
        countryData: null,
    },
    reducers: {
        addSelectedModule: (state, action) => {
            state.selectedModules = action.payload;
        },
        removeSelectedModule: (state, action) => {
            state.selectedModules = action.payload;
        },
        setCalculateddata: (state, action) => {
            state.calculateddata = action.payload;
        },
        setAnnualDiscount: (state, action) => {
            state.annualDiscount = action.payload;
        }
    },
    extraReducers: (builder) => {
        //Ip data
        builder.addCase(fetchIp.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIp.fulfilled, (state, action) => {
            state.isLoading = false;
            state.countryData = action.payload;
        });
        builder.addCase(fetchIp.rejected, (state, action) => {
            state.isError = true;
        });

        //faq data
        builder.addCase(fetchFaqsData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFaqsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.faqdata = action.payload.pagelist;
        });
        builder.addCase(fetchFaqsData.rejected, (state, action) => {
            state.isError = true;
        });

        //module data
        builder.addCase(fetchModuleData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchModuleData.fulfilled, (state, action) => {
            state.isLoading = false;

            state.moduledata = action.payload.data.menu_list;

            if (state.selectedModules.length > 0) {
                const selectedIds = state.selectedModules.map(item => item.id);
                const filteredMenuList = action.payload.data.menu_list.filter(menu =>
                    menu.child_list.some(child => selectedIds.includes(child.id))
                );
                const result = filteredMenuList.map((ele) =>
                    ele.child_list.filter((data) =>
                        selectedIds.includes(data.id)
                    )
                ).flat();

                state.selectedModules = result;

                const grandTotal = result.reduce((sum, { price, tax_percent }) => {
                    const numericPrice = parseFloat(price);
                    const numericTaxPercent = parseFloat(tax_percent);
                    const taxAmount = (numericPrice * numericTaxPercent) / 100;
                    const totalForItem = numericPrice + taxAmount;
                    return sum + totalForItem;
                }, 0);
                const grand = grandTotal.toFixed(2);
                
                let totalSaving = 0;

                if (action.payload.duration === "annual") {
                    totalSaving = result.reduce((sum, { annual_discount }) => {
                        return sum + parseFloat(annual_discount || 0);
                    }, 0);
                }
                let actualTotal = 0;
                if(totalSaving !== 0){
                    actualTotal = grand - totalSaving;
                }else{
                    actualTotal = grand;
                }

                state.calculateddata = { ...state.calculateddata, grandTotal: actualTotal, totalSaving: totalSaving.toFixed(2) };
                
            }

        });
        builder.addCase(fetchModuleData.rejected, (state, action) => {
            state.isError = true;
        });

        //Save Contact
        builder.addCase(saveContact.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(saveContact.fulfilled, (state, action) => {
            state.isLoading = false;
            state.contactData = action.payload;
        });
        builder.addCase(saveContact.rejected, (state, action) => {
            state.isError = true;
        });

        //Images List
        builder.addCase(fetchImagesData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchImagesData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.imagesList = action.payload.data;
        });
        builder.addCase(fetchImagesData.rejected, (state, action) => {
            state.isError = true;
        });

        //Images List
        builder.addCase(fetchNavigationData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchNavigationData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.navigationList = action.payload.data.pagelist;
        });
        builder.addCase(fetchNavigationData.rejected, (state, action) => {
            state.isError = true;
        });
    }
});

export const { addSelectedModule, removeSelectedModule, setCalculateddata, setAnnualDiscount } = masterSlice.actions;

export default masterSlice.reducer;