import React from 'react'
import { FaCheck } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive'

const AboutCommon = ({ data }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div class="about-section section-padding right-col-full" style={{backgroundColor: data.bg_color}}>
            <div class="container">
                <div class={`row align-items-center clearfix ${data.rowReverse}`}>
                    <div class="col-lg-6" data-aos="fade-left">
                        <div class="heading-wrapper with-separator">
                            <div dangerouslySetInnerHTML={{ __html: data.heading }} />
                        </div>
                        <div class="text-wrapper pt-3">
                            {data.description}
                            <ul class="list-style-disc">
                                {data.list.map((ele, i) => {
                                    return (
                                        <li key={i} className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> {ele}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-center" data-aos="fade-up">
                        <img src={data.img} alt="" class="img-fluid" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutCommon
