import React from 'react'
import img1 from '../images/detailImage/dashboard-img-1.png'
import img2 from '../images/detailImage/bg-1.png'
import img3 from '../images/detailImage/dashboard-img-2.png'
import img4 from '../images/detailImage/laptop-screen.png'
import img5 from '../images/detailImage/ss-1.jpg'
import img6 from '../images/detailImage/ss-2.jpg'
import img7 from '../images/detailImage/ss-3.jpg'
import CommonFeatureComponent from './CommonFeatureComponent'
import ContactCommon from './ContactCommon'
import { LiaUserFriendsSolid } from "react-icons/lia";
import { BiCustomize } from "react-icons/bi";
import { GrIntegration } from "react-icons/gr";
import { TbReorder } from "react-icons/tb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from 'react-responsive'
import { FaCheck } from "react-icons/fa6";

const AdminComponent = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const data = {
    "heading": '<h2>Why Choose <span class="primary-text">ShopersBay Admin Panel</span> Software?</h2>',
    "description": "ShopersBay Admin Panel Software effectively addresses e-commerce management challenges by offering a comprehensive suite of tools that simplify inventory tracking, order processing, and customer engagement. With an intuitive interface and powerful features, it enhances productivity, streamlines business operations, and empowers businesses to efficiently manage every aspect of their online store.",
    "list": [
      {
        "icon": <LiaUserFriendsSolid size={70} />,
        "title": "User-Friendly Interface",
        "descpt": "The platform is designed with a clean and intuitive interface, making it easy for users of all technical backgrounds to navigate and manage their stores.",
      },
      {
        "icon": <BiCustomize size={70} />,
        "title": "Customizability",
        "descpt": "ShopersBay understands that every business has unique needs. The admin panel is highly customizable, allowing store owners to configure settings and features according to their requirements.",
      },
      {
        "icon": <GrIntegration size={70} />,
        "title": "Integration Capabilities",
        "descpt": "The software seamlessly integrates with third-party applications such as payment gateways, shipping providers, and marketing tools, ensuring a unified e-commerce ecosystem.",
      },
      {
        "icon": <TbReorder size={70} />,
        "title": "Scalable Architecture",
        "descpt": "ShopersBay’s Admin Panel scales effortlessly, supporting unlimited products, users, and orders, ideal for startups and enterprises alike.",
      }
    ],
    "rowReverse": ""
  }
  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: 'linear',
  };
  return (
    <div className='admin-component'>
      <div className="page-header section-padding full-height right-col-full dc-one" style={{ backgroundImage: `url(${img2})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading-wrapper with-separator" data-aos="fade-up-right">
                <h1>ShopersBay Admin Panel: <span className='primary-text'>Streamlined E-commerce</span> Management</h1>
              </div>
              <div className="text-wrapper" data-aos="fade-up" data-wow-delay="0.2s">
                <h6>ShopersBay's Admin Panel Software empowers e-commerce store owners with intuitive tools for managing products, orders, and customers, ensuring efficient and effective online store management.</h6>
              </div>
              <div data-aos="fade-right"><button type="button" className='common-btn padding-common-btn'>Get Started For Free</button></div>
            </div>
            <div className="col-lg-6">
              <div className="image-wrapper full-width-img">
                <img src={img1} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFeatureComponent data={data} />
      <div className="about-section section-padding light-gradient-bg right-col-full">
        <div className="container">
          <div className="row clearfix align-items-center">
            <div className="col-lg-6">
              <div className="heading-wrapper with-separator">
                <h2>Key Features of ShopersBay <span className='primary-text'>Admin Panel</span></h2>
              </div>

              <div className="text-wrapper">
                <h6 className='mb-3'>The ShopersBay Admin Panel is packed with features designed to streamline the workflow of e-commerce management. Here are some of its key functionalities:</h6>
                <ul className="list-style-disc">
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Product Management</li>
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Inventory Control</li>
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Order Management</li>
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Customer Relationship Management (CRM)</li>
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> Sales Analytics and Reports</li>
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> User Access Control and Security</li>
                  <li className={`${isMobile ? "mb-2" : "mb-1"}`}><FaCheck fill="#7CBB00" size={10} /> SEO and Marketing Tools</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={img3} alt="" className='img-fuid' />
            </div>
          </div>
        </div>
      </div>
      <div class="screenshot-section section-padding">
        <div class="container">
          <div class="row justify-content-center clearfix style-dark">
            <div class="col-lg-8">
              <div class="heading-wrapper text-center">
                <h2>Software Screen Shots</h2>
                <div class="lead-text">
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque.</p> */}
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="ss-wrapper">
                <img class="laptop-img-bg" src={img4} alt="" />
                <Slider {...settings} className='screenshot-slider'>
                  <div class="item">
                    <img src={img5} alt="" className='img-fluid' />
                  </div>
                  <div class="item">
                    <img src={img6} alt="" className='img-fluid' />
                  </div>
                  <div class="item">
                    <img src={img7} alt="" className='img-fluid' />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactCommon />
    </div>
  )
}

export default AdminComponent
